import { defineComponent, reactive, toRefs, watch } from "vue";
import { getByUrl } from "@/utils/http";
import { useRoute, useRouter } from "vue-router";

interface Data {
  typeId: string | string[];
  businessAreaDetails: {
    businessIntroduce: null;
    businessName: string;
    businessTitle: null;
    firstId: number;
    id: number;
    imgPath: null;
  } | null;
  secondTypes: Array<{
    businessIntroduce: string;
    businessName: string;
    businessTitle: string;
    firstId: number;
    id: number;
    imgPath: null;
  }>;
  solutionList: Array<{
    businessTypeId: number;
    id: number;
    imgDetails: string;
    imgIntroduce: string;
    imgPath: string;
    imgTitle: string;
    isHomePage: null;
  }>;
  jumpPage: (name: string, id: number) => void;
}

export default defineComponent({
  name: "BusinessAreas",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { typeId } = route.params;

    const data: Data = reactive({
      typeId,
      secondTypes: [],
      solutionList: [],
      businessAreaDetails: null,
      jumpPage: (name, id) => {
        router.push({ name, params: name === "SolutionDetails" ? { solutionId: id } : { typeId: id } });
      }
    });

    const queryBusinessArea = (typeId: string | string[]) => {
      data.typeId = typeId;
      getByUrl("/businessArea/getBusinessArea", { typeId }).then(res => {
        const { businessAreaList, secondTypes, solutionList } = res;
        data.businessAreaDetails = businessAreaList[0];
        data.secondTypes = secondTypes;
        data.solutionList = solutionList;
      });
    };

    queryBusinessArea(typeId);

    watch(
      () => route.params,
      ({ typeId }) => {
        if (typeof typeId === "undefined" || !typeId || typeId === "undefined") return;
        queryBusinessArea(typeId);
      }
    );

    return {
      ...toRefs(data)
    };
  }
});
