<template>
  <div class="business-areas">
    <div class="type-selection">
      <div>
        <div v-for="item in secondTypes" :key="`secondTypes-${item.id}`" :class="{ active: +typeId === item.id }" @click="jumpPage('BusinessAreas', item.id)">
          {{ item.businessName }}
        </div>
      </div>
      <!-- <div>
        <div class="active">降水监测</div>
        <div>湖库监测</div>
        <div>河流监测</div>
        <div>海洋监测</div>
        <div>地下水监测</div>
      </div> -->
    </div>
    <div v-if="businessAreaDetails">
      <h1>{{ businessAreaDetails.businessTitle }}</h1>
      <div>
        <p>
          {{ businessAreaDetails.businessIntroduce }}
        </p>
      </div>
      <h2 v-if="solutionList.length">解决方案</h2>
      <div class="solution">
        <div v-for="item in solutionList" :key="`solutionList-${item.id}`" @click="jumpPage('SolutionDetails', item.id)">
          <img :src="item.imgPath" :alt="item.imgTitle" />
          <div class="solution-info">
            <h3>{{ item.imgTitle }}</h3>
            <div>
              <p>
                {{ item.imgIntroduce }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>

<style lang="scss" scoped>
.business-areas {
  background-color: #f6f6f6;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-image: url("~@/assets/image/business-areas-page-bg.jpg");
  background-size: 100%;
  padding-bottom: 73px;
  padding-top: 60px;
  > div {
    width: $effectiveArea;
    margin: 0 auto;
  }
  .type-selection {
    text-align: center;
    > div {
      display: flex;
      justify-content: center;
      &:first-child {
        > div {
          cursor: pointer;
          padding-bottom: 3px;
          border-bottom: 3px solid;
          border-color: transparent;
          font-weight: bold;
          & + div {
            margin-left: 30px;
          }
          &.active {
            border-color: $defaultFontColor;
          }
        }
      }
      // &:last-child {
      //   margin-top: 16px;
      //   margin-bottom: 76px;
      //   > div {
      //     width: 105px;
      //     height: 40px;
      //     border-radius: 4px;
      //     line-height: 40px;
      //     text-align: center;
      //     border: 1px solid #7d7d7d;
      //     box-sizing: border-box;
      //     transition-duration: 0.3s;
      //     cursor: pointer;
      //     & + div {
      //       margin-left: 18px;
      //     }
      //     &:hover,
      //     &.active {
      //       background-color: #00a0e9;
      //       border-color: transparent;
      //       color: #fff;
      //     }
      //   }
      // }
    }
  }
  h1,
  h2 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
  }
  h1 {
    margin-bottom: 52px;
  }
  h2 {
    margin-top: 44px;
    margin-bottom: 48px;
  }
  p {
    line-height: 26px;
    text-indent: 2em;
    font-size: 14px;
  }
  .solution {
    > div {
      display: flex;
      background-color: #fff;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      transition-duration: 0.3s;
      & + div {
        margin-top: 40px;
      }
      &:hover {
        box-shadow: 0 0 8px rgb(0 0 0 / 10%);
        transform: scale(1.036);
        h3 {
          color: $focusFontColor;
        }
      }
    }
    img {
      width: 554px;
      height: 327px;
    }
    &-info {
      padding: 32px 40px;
    }
  }
}
</style>
